const _ = require('lodash');
const loadingClass = 'is-loading';
let $partnersListFilters = $('#js-partners-list-filters'),
    $options = $('#js-partners-filters-options'),
    $search = $('#js-partner-filters-search'),
    type = '.js-partner-filters-type';

// Paginacja
$(document).on('click', '.js-pagination-goto', function (e) {
    e.preventDefault();

    let page = $(this).data('page');
    let $form = $(this).closest('form');

    $form.find('input[name="cp"]').val(page);

    $form.trigger('submit');
});

$(document).on('change', 'input[name="cpi"]', function (e) {
    e.preventDefault();
    let $form = $(this).closest('form');
    let value = $(e.target).val();
    $form.find('input[name="cp"]').val(value);
});

// Listing
$(document).on('submit', '#js-partners-list-form', function (e) {
    e.preventDefault();
    update_partners_list($(this));
    $('#js-partners-list-filters, .js-filter-trigger').removeClass('is-active');
    $('body, html').removeClass('is-no-scroll');
});

$('.js-partner-list-cities').on('change', function (e) {
    e.preventDefault();
    let $form = $('#js-partners-list-form');
    $form.find('[name="partners_local"]').val('all');
    $form.trigger('submit');
});

$(document).on(
    'change',
    '.js-partner-list-filter, .js-partner-list-locals',
    function (e) {
        e.preventDefault();
        let $form = $('#js-partners-list-form');
        $form.find('input[name="cp"]').val(1);
        $form.trigger('submit');
    }
);

$(document).on('click', '.js-partner-list-submit', function (e) {
    e.preventDefault();
    let $form = $('#js-partners-list-form');
    $form.find('input[name="cp"]').val(1);
    $form.trigger('submit');
});

function update_partners_list($form) {
    let formData = $form.serialize();
    let $partnersList = $('#js-partners-list');
    let $pagination = $('#js-partners-list-pagination');
    let selectedOption = 0;

    $.ajax({
        type: 'post',
        url: ajax.url,
        data: formData,
        beforeSend: function () {
            $form.addClass(loadingClass);
            console.log('parnters', $form);
            window.scroll({
                top: $form.offset().top - (window.innerWidth > 900 ? 250 : 30),
                behavior: 'smooth',
            });
        },
        success: function (response) {
            history.pushState({}, null, response.url);
            $partnersList.html(response.list);

            let newOptions = [];
            selectedOption = $('.js-partner-list-locals')
                .find(':selected')
                .val();
            $.each(response.locals_options, function (key, value) {
                let option = new Option(value.title, value.id, false, false);
                if (selectedOption.length > 0 && selectedOption == value.id) {
                    option = new Option(value.title, value.id, true, true);
                }
                newOptions.push(option);
            });

            $('.js-partner-list-locals').html(newOptions);

            $pagination.html(response.pagination);
            $form.removeClass(loadingClass);
        },
    });
}

// Typ wyszukiwania - miasto lub lokal
$(type).on('change', function () {
    let typeChecked = $(this).val();
    $.ajax({
        type: 'post',
        url: ajax.url,
        data: {
            action: 'mda_partner_filters_type',
            type: typeChecked,
        },
        beforeSend: function () {
            $options.html('');
            $partnersListFilters.find('.load-component').addClass(loadingClass);
            $search.val('');
        },
        success: function (response) {
            $options.html(response.list);
            $partnersListFilters
                .find('.load-component')
                .removeClass(loadingClass);
            $search.attr('placeholder', response.placeholder);
        },
    });
});

// Wyszukiwanie po nazwie
$search.on(
    'keyup',
    _.debounce(function () {
        let s = $(this).val();
        let typeChecked = $(`${type}:checked`).val();
        $.ajax({
            type: 'post',
            url: ajax.url,
            data: {
                action: 'mda_partner_filters_search',
                type: typeChecked,
                s: s,
            },
            beforeSend: function () {
                $partnersListFilters
                    .find('.load-component')
                    .addClass(loadingClass);
            },
            success: function (response) {
                $options.html('');
                $options.html(response.list);
                $partnersListFilters
                    .find('.load-component')
                    .removeClass(loadingClass);
            },
        });
    }, 500)
);
